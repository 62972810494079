import Tab from "./Tab";

import { MdAlbum, MdShelves, MdPerson, MdAdd } from "react-icons/md";

const Nav = () => {
  return (
    <div className="md:left-0 md:h-screen md:w-20 md:pt-20 md:px-1 w-screen bottom-0 fixed bg-gray-800 h-20 z-40 px-2">
      <nav className="md:h-auto md:w-full md:flex-col md:gap-1 md:pt-1 container xl mx-auto block h-full flex justify-around">
        <Tab Icon={MdAlbum} name={"Albums"} location={"/"} />
        <Tab Icon={MdShelves} name={"Locations"} location={"/locations"} altLocation="/location/:id" />
        <Tab Icon={MdPerson} name={"Artists"} location={"/artists"} altLocation="/artist/:id" />
        <Tab Icon={MdAdd} name={"Add Album"} location={"/add"} />
      </nav>
    </div>
  );
};

export default Nav;