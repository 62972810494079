import { useState } from "react";

const Dropdown = ({name, id, label, placeholder, className, labelWidth, value, setValue, error, data}) => {
  const [typing, setTyping] = useState(false);
  const [chosingOption, setChosingOption] = useState(false);

  let options = [];

  data.forEach((item, index) => {
    options.push(
      <button key={index} type="button" onClick={e => {setValue(item); setChosingOption(false);}} className="text-left w-full p-2 bg-gray-900 truncate text-lg cursor-pointer hover:bg-gray-800">{item}</button>
    );
  });

  return(
    <div className="relative">
      <div className={"pointer-events-none flex overflow-hidden h-10 " + (className || "")}>
        <label htmlFor={id} className={((error >= 1) ? "border-red-700" : "border-gray-700")+" h-full text-lg text-white flex items-center rounded-l-lg border-2 border-r-0 border-gray-700 pointer-events-auto bg-gray-800 px-2 justify-center cursor-pointer " + (labelWidth || "")}>{label}</label>
        <input className={((error >= 1) ? "border-red-700" : "border-gray-700")+" h-full p-2 grow text-lg pointer-events-auto z-10 border-2 border-gray-700 rounded-r-lg outline-none bg-gray-900 text-white focus:border-amber-700 transition-colors duration-150 w-1 truncate " + ((typing || chosingOption) && 'rounded-br-lg')} type='text' id={id} name={name} placeholder={placeholder} value={value} onInput={e => setValue(e.target.value)} onFocus={e => setTyping(true)} onBlur={e => setTyping(false)}/>
        {(typing || chosingOption) && <span className="absolute border-r-2 bottom-0 right-0 w-4 text-gray-700 border-gray-700 h-4 bg-gray-900 z-0"></span>}
      </div>
      <div className={"absolute w-full flex " + (!(typing || chosingOption) && 'hidden')} onMouseOver={e => setChosingOption(true)} onMouseOut={e => setChosingOption(false)}>
        <div className={(labelWidth) + ' shrink-0 pointer-events-none'}></div>
        <div className="border-gray-700 border-2 border-t-0 grow rounded-b-lg grid gap-0.5 bg-gray-700 max-h-36 overflow-y-scroll">
          {options}
        </div>
      </div>
    </div>
  )
}

export default Dropdown;