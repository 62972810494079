import { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "./Components/Input"
import Dropdown from "./Components/Dropdown";
import { apiHost } from "../Functionality/SharedVars";

const Add = ({className, loggedIn}) => {
  const [error, setError] = useState({name: 0, art: 0, artist: 0, sides: 0})

  const [name, setName] = useState('');

  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const [art, setArt] = useState(null);
  const [artError, setArtError] = useState(0);

  const [artist, setArtist] = useState('');
  const [artistList, setArtistList] = useState([]);
  
  const [sides, setSides] = useState('0');

  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedIn) {
      navigate('/login');
    }
  },[loggedIn, navigate]);

  useEffect(() => {
    let tempError = {...error}
    if (file) {
      if (file.type.startsWith('image/')) {
        const artUrl = URL.createObjectURL(file);
        setArt(artUrl);
        tempError.art = 0;
        setArtError(tempError);
      } else {
      setFileName('');
      setFile(null);
      setArt(null);
      tempError.art = 1;
      setArtError(tempError);
    }
    } else if (fileName !== '') {
      setFileName('');
      setFile(null);
      setArt(null);
      tempError.art = 1;
      setArtError(tempError);
    }
  },[file, fileName, error]);

  useEffect(() => {
    const getArtists = async () => {
      try {
        const response = await fetch(
          apiHost+'/api/vinylVault/getArtistList.php?name='+artist, {
            method: 'POST',
            body: JSON.stringify({a: 'egg'})
          }
        );
        if (!response.ok) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const result = await response.json();
        let tempList = [];
        result.data.forEach(artist => {
          tempList.push(artist['name']);
        });
        setArtistList(tempList);
        console.log(tempList)
      } catch (error) {
        setArtistList([null]);
      }
    }

    getArtists();
  },[artist])

  return (
    <section className={(className || "")}>
      <h1 className="hidden">Add Album</h1>
      <form className="max-w-screen-2xl mx-auto grid gap-2 p-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        <div className="sm:col-span-2 grid gap-2 grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-col lg:col-span-1">
          <ArtCard art={art} />
          <InfoCard name={name} setName={setName} fileName={fileName} setFileName={setFileName} file={file} setFile={setFile} artist={artist} setArtist={setArtist} artistList={artistList} sides={sides} setSides={setSides} error={error} />
        </div>
        <div className="sm:col-span-2">
          <div className=" grid gap-2 grid-cols-1 sm:grid-cols-2">
          </div>
        </div>
      </form>
    </section>
  )
}

const ArtCard = ({art}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(()=>{
    setIsLoaded(false);
  },[art])

  return (
    <div className="rounded-lg overflow-hidden aspect-square">
      <img src={(art || '')} alt='' className={"w-full aspect-square " + (isLoaded ? "" : " hidden")} onLoad={(e) => {setIsLoaded(true);}} />
      <span className={(isLoaded ? "hidden" : "w-full aspect-square bg-gray-800 block") + ((!isLoaded) && art ? ' animate-pulse' : '')}></span>
    </div>
  )
}

const InfoCard = ({name, setName, fileName, setFileName, file, setFile, artist, setArtist, artistList, sides, setSides, error}) => {
  return (
    <div className="sm:rounded-lg sm:max-lg:aspect-square sm:bg-slate-900 text-white p-2 flex flex-col justify-between gap-4">
      <div>
        <Input name={'art'} id={'art'} type={'file'} label={'Art'} placeholder={'Select an image'} labelWidth={'w-24'} value={fileName} setValue={setFileName} file={file} setFile={setFile} error={error.art} accept={"image/*"} />
        <Input name={'name'} id={'name'} type={'text'} label={'Name'} placeholder={'Album Name'} labelWidth={'w-24'} value={name} setValue={setName} error={error.name} />
        <Dropdown name={'artist'} id={'artist'} label={'Artist'} placeholder={'Choose an Artist'} labelWidth={'w-24'} value={artist} setValue={setArtist} data={artistList} error={error.artist} />
        <Input name={'sides'} id={'sides'} type={'text'} label={'Sides'} placeholder={'Number of sides'} labelWidth={'w-24'} value={sides} setValue={setSides} error={error.sides} />
        <p className="text-3xl truncate leading-normal">name</p>
        <p className="truncate text-lg text-gray-200 leading-normal">Artist</p>
        <p className="truncate leading-normal">songCount Songs</p>
      </div>
      <div>
        <p className="text-gray-200 truncate leading-normal">Located in: Location</p>
        <p className="text-gray-200 truncate leading-normal">Owned by: owner</p>
      </div>
    </div>
  )
}

const SideCard = ({side, sideNumber, songId}) => {
  let songs = [];
  for (let i = 0; i < side.length; i++) {
    let number = side[i].number;
    let name = side[i].name;
    let id = side[i].id;
    songs.push(
      <tr key={i} className={id === parseInt(songId) ? "font-black text-amber-500" : ""}>
        <td className="align-top">{number}</td>
        <td className="align-top">-</td>
        <td className="text-balance">{name}</td>
      </tr>
    )
  }
  return (
    <div className="rounded-lg overflow-hidden bg-slate-900 text-white p-2 flex flex-col">
      <h3 className="text-lg">Side {sideNumber}</h3>
      <table>
        <tbody>
          {songs}
        </tbody>
      </table>
    </div>
  )
}

export default Add;