import { Link } from 'react-router-dom';
import { apiHost } from '../../Functionality/SharedVars';
import { useState } from 'react';

const AlbumCards = (data) => {
  let albumArray = data.albums;
  let albums = [];
  for (let i = 0; i < albumArray.length; i++) {
    let artLocation = apiHost+"/api/vinylVault/albumArt/" + albumArray[i].art;
    let albumLocation = "/album/" + albumArray[i].id;
    albums.push(
      <AlbumCard key={i} albumLocation={albumLocation} artLocation={artLocation} album={albumArray[i]}/>
    );
  }
  return (
    <div className="max-w-screen-2xl mx-auto grid gap-2 p-2 grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:grid-cols-6">
      {albums}
    </div>
  );
}

const AlbumCard = ({albumLocation, artLocation, album}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Link to={albumLocation} className="flex rounded-lg overflow-hidden group hover:scale-[1.01] aspect-square flex-col sm:brightness-75 hover:filter-none justify-end">
      <div className='h-0'>
        <img src={artLocation} alt='' className={"w-full aspect-square " + (isLoaded ? "" : " hidden")} onLoad={(e) => {setIsLoaded(true);}} />
        <span className={isLoaded ? "hidden" : "w-full aspect-square bg-gray-800 block animate-pulse"}></span>
      </div>
      <div className='grow justify-end flex-col group-hover:opacity-100 flex sm:opacity-0'>
        <div className='p-1 bg-gray-900/50 text-white backdrop-blur-sm'>
          <p className='truncate text-xs leading-snug font-light'>{album.artist}</p>
          <p className='truncate leading-snug'>{album.name}</p>
        </div>
      </div>
    </Link>
  )
}

export default AlbumCards;