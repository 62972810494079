import { MdFileUpload } from "react-icons/md";

const Input = ({name, id, type, label, placeholder, className, labelWidth, value, setValue, error, file, setFile, accept, filterFunction}) => {

  const handleFile = (e) => {
    setValue(e.target.value);
    setFile(e.target.files[0]);
    
  }

  const handleInput = (input) => {
    if (filterFunction) {
      setValue(filterFunction(input));
    } else {
      setValue(input);
    }
  }

  return (
    <div className={"flex overflow-hidden h-10 " + (className ? className : "")}>
      <label htmlFor={id} className={((error >= 1) ? "border-red-700" : "border-gray-700") + " h-full text-lg text-white flex items-center rounded-l-lg border-2 border-r-0 border-gray-700 bg-gray-800 px-2 justify-center cursor-pointer " + (labelWidth ? labelWidth : "")}>{label}</label>
      
      {(type !== 'file' ? (
        <input className={((error >= 1) ? "border-red-700" : "border-gray-700") + " h-full p-2 grow text-lg border-2 border-gray-700 rounded-r-lg outline-none bg-gray-900 text-white focus:border-amber-700 transition-colors duration-150 w-1 truncate "} type={type} id={id} name={name} placeholder={placeholder} value={value} onInput={e => handleInput(e.target.value)} />
      ) : (
        /* For file input */
        <input className="hidden" type={type} id={id} name={name} placeholder={placeholder} value={value} onChange={handleFile} accept={accept} />
      ))}
      {(type === 'file' && (
        /* For file input */
        <label htmlFor={id} className={((error >= 1) ? "border-red-700" : "border-gray-700") + " flex border-2 items-center h-full grow text-lg border-l-2 border-gray-700 rounded-r-lg bg-amber-500 text-white active:border-amber-700 transition-colors duration-150 px-2 w-1 hover:bg-amber-600 cursor-pointer truncate "}><MdFileUpload className="text-2xl shrink-0"/><p className="truncate grow-0">{(value.split(/(\\|\/)/g).pop() || placeholder)}</p></label>
      ))}
    </div>
  )
}

export default Input;