import logo from '../Images/logo.svg';
import { MdSearch, MdLogin, MdLogout, MdAccountCircle } from "react-icons/md";
import { SearchContext } from "../Functionality/SearchContext";
import { useContext, useState } from 'react';
import Tab from './Tab';

const Header = ({loggedIn, userId, username}) => {
  const {searchValue, setSearchValue} = useContext(SearchContext);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className="fixed top-0 h-auto w-screen bg-gray-900 z-50">
      <div className="h-20 w-full flex items-center gap-4 p-2">
        <img src={logo} className="h-full cursor-pointer" alt="logo" onClick={() => {setMenuOpen(!menuOpen)}} />
        <div className='grow flex justify-center'>
          <div className='grow flex overflow-hidden max-w-4xl'>
            <input className='h-12 p-4 grow text-lg border-2 border-gray-700 rounded-l-lg outline-none bg-gray-900 text-white focus:border-amber-700 transition-colors duration-150 w-1' placeholder='Search' id="search" value={searchValue} onInput={e => setSearchValue(e.target.value)}/>
            <label className='text-3xl text-white flex items-center rounded-r-lg border-2 border-l-0 border-gray-700 w-20 bg-gray-800 justify-center cursor-pointer hover:bg-gray-700' htmlFor="search">
              <MdSearch />
            </label>
          </div>
        </div>
      </div>
      <div className={"bg-gray-700 overflow-x-scroll box-content transition-all overflow-hidden text-white flex gap-2 p-2 " + (menuOpen ? 'h-16' : 'h-0  py-0')}>
        {loggedIn ? <div className={"rounded-lg flex bg-gray-800 p-4 items-center gap-1 text-xl h-16 transition-all"}>
          <MdAccountCircle className='text-3xl' />
          <p>{username}</p>
        </div> : <></>}
        {loggedIn ? <Tab Icon={MdLogout} name="Logout" location="/logout" className={(menuOpen ? 'opacity-100' : 'opacity-0')} menuOpenData={{menuOpen, setMenuOpen}} /> : <Tab Icon={MdLogin} name="Login" location="/login" className={(menuOpen ? 'opacity-100' : 'opacity-0')} menuOpenData={{menuOpen, setMenuOpen}} />}
      </div>
    </header>
  );
};

export default Header;