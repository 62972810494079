import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { apiHost } from "../Functionality/SharedVars";
import LoadingIcon from "./Components/LoadingIcon";

const Album = ({className = ""}) => {
  const albumId = useParams().id;
  const songId = useParams().song;
  const [name, setName] = useState(null);
  const [art, setArt] = useState(null);
  const [artist, setArtist] = useState(null);
  const [artistId, setArtistId] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationId, setLocationId] = useState(null);
  const [owner, setOwner] = useState(null);
  const [songCount, setSongCount] = useState(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const getAlbums = async () => {
      try {
        if (albumId.length < 1) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const response = await fetch(
          apiHost+'/api/vinylVault/getAlbum.php?id='+albumId, {
            method: 'POST',
            body: JSON.stringify({a: 'egg'})
          }
        );
        setResponseCode(response.status);
        if (!response.ok) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const result = await response.json();
        setData(result.data);
        setName(result.extraData.name);
        setArt(result.extraData.art);
        setArtist(result.extraData.artist);
        setArtistId(result.extraData.artistId);
        setLocation(result.extraData.location);
        setLocationId(result.extraData.locationId);
        setSongCount(result.extraData.songCount);
        setOwner(result.extraData.owner);
        setError(null);
      } catch (error) {
        setError(error);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    getAlbums();
  }, [loading, albumId]);

  if (error === null && data === null) {
    return (
      <section className={(className || "") + " flex p-20 items-center flex-col"}>
        <h1 className="hidden">Album</h1>
        <LoadingIcon className=""/>
      </section>
    );
  } else if (data === null && (!(error === null) || !(responseCode === 200))) {
    return (
      <section className={(className || "") + " text-white flex flex-col align-center text-center p-8 text-2xl gap-4 max-w-screen-md mx-auto"}>
        <h1 className="hidden">Album</h1>
        <p>Oops! Looks like we may have messed up, try again later!</p>
        <p className="text-xl text-gray-500">Response code: {responseCode ? responseCode : "503"}</p>
      </section>
    );
  } else {
    let sides=[];
    for (let i = 1; i <= Object.keys(data).length; i++) {
      sides.push(<SideCard side={data[i]} sideNumber={i} songId={songId} key={i} />);
    }
    return (
      <section className={(className || "")}>
        <h1 className="hidden">{name}</h1>
        <div className="max-w-screen-2xl mx-auto grid gap-2 p-2 grid-cols-1 min-[500px]:grid-cols-2 lg:grid-cols-3">
          <div className="min-[500px]:col-span-2 grid gap-2 grid-cols-1 min-[500px]:grid-cols-2 lg:flex lg:flex-col lg:col-span-1">
            <ArtCard art={art}/>
            <InfoCard name={name} artist={artist} artistId={artistId} location={location} locationId={locationId} owner={owner} songCount={songCount} />
          </div>
          <div className="min-[500px]:col-span-2">
            <div className=" grid gap-2 grid-cols-1 min-[500px]:grid-cols-2">
              {sides}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const ArtCard = ({art}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <div className="rounded-lg overflow-hidden aspect-square">
      <img src={apiHost+"/api/vinylVault/albumArt/" + art} alt='' className={"w-full aspect-square " + (isLoaded ? "" : " hidden")} onLoad={(e) => {setIsLoaded(true);}} />
      <span className={isLoaded ? "hidden" : "w-full aspect-square bg-gray-800 block animate-pulse"}></span>
    </div>
  )
}

const InfoCard = ({name, artist, artistId, location, locationId, owner, songCount}) => {
  return (
    <div className="min-[500px]:rounded-lg overflow-hidden min-[500px]:max-lg:aspect-square min-[500px]:bg-slate-900 text-white p-2 flex flex-col justify-between gap-4">
      <div>
        <p className="text-3xl truncate leading-normal">{name}</p>
        <p className="truncate text-lg text-gray-200 leading-normal"><Link className="hover:text-white" to={"/artist/" + artistId}>{artist}</Link></p>
        <p className="truncate leading-normal">{songCount} Songs</p>
      </div>
      <div>
        <p className="text-gray-200 truncate leading-normal">Located in: <Link className="hover:text-white" to={"/location/" + locationId}>{location}</Link></p>
        <p className="text-gray-200 truncate leading-normal">Owned by: {owner}</p>
      </div>
    </div>
  )
}

const SideCard = ({side, sideNumber, songId}) => {
  let songs = [];
  for (let i = 0; i < side.length; i++) {
    let number = side[i].number;
    let name = side[i].name;
    let id = side[i].id;
    songs.push(
      <tr key={i} className={id === parseInt(songId) ? "font-black text-amber-500" : ""}>
        <td className="align-top">{number}</td>
        <td className="align-top">-</td>
        <td className="text-balance">{name}</td>
      </tr>
    )
  }
  return (
    <div className="rounded-lg overflow-hidden bg-slate-900 text-white p-2 flex flex-col">
      <h3 className="text-lg">Side {sideNumber}</h3>
      <table>
        <tbody>
          {songs}
        </tbody>
      </table>
    </div>
  )
}

export default Album;