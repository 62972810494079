import { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

const Checkbox = ({id, name, label, value, setValue}) => {

  const [checked, setChecked] = useState((value ? true : false))
  
  useEffect(() => {
    setValue(checked);
  },[checked, setValue])

  return (
    <div>
      <label htmlFor={id} className="flex text-white text-lg gap-2 group w-fit">
        <input className="hidden" type="checkbox" name={name} id={id} value={checked} onChange={(e) => {setChecked(!checked)}} />
        <span className={"h-7 w-7 flex items-center justify-center rounded-lg border-2 " + (checked ? "bg-amber-500 border-amber-800 group-hover:bg-amber-400" : "bg-gray-900 border-gray-700 group-hover:bg-gray-800")}>{checked ? <FaCheck /> : ""}</span>
        <p>{label}</p>
      </label>
    </div>
  )
}

export default Checkbox;