import { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import { SearchContext } from "../Functionality/SearchContext";
import Home from "./Home";
import Locations from "./Locations";
import Artists from "./Artists";
import Add from "./Add";

import Login from "./Login";
import Logout from "./Logout";

import Location from "./Location";
import Artist from "./Artist";

import Search from "./Search";
import Album from "./Album";

const Main = ({loggedIn, userId}) => {
  const {searchValue} = useContext(SearchContext);
  const [searching, setSearching] = useState(false);
  
  useEffect(() => {
    switch (searchValue.trim().length) {
      case 0:
        setSearching(false);
        break;
      default:
        setSearching(true);
        break;
    }
  },[searchValue]);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home className={searching === true && "hidden"}/>}></Route>
        <Route path='/locations' element={<Locations className={searching === true && "hidden"}/>}></Route>
        <Route path='/artists' element={<Artists className={searching === true && "hidden"}/>}></Route>
        <Route path='/add' element={<Add className={searching === true && "hidden"} loggedIn={loggedIn} />}></Route>

        <Route path='/login' element={<Login className={searching === true && "hidden"} loggedIn={loggedIn} />}></Route>
        <Route path='/logout' element={<Logout className={searching === true && "hidden"} />}></Route>

        <Route path='/album/:id/:song?' element={<Album className={searching === true && "hidden"}/>}></Route>
        <Route path='/location/:id' element={<Location className={searching === true && "hidden"}/>}></Route>
        <Route path='/artist/:id' element={<Artist className={searching === true && "hidden"}/>}></Route>
      </Routes>
      
      {searching === true && (
        <Search search={searchValue} />
      )}
    </>
  );
};

export default Main;