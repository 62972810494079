import { useState } from "react";
import Button from "./Components/Button";
import Checkbox from "./Components/Checkbox";
import Input from "./Components/Input";
import { apiHost } from "../Functionality/SharedVars";
import { useNavigate } from "react-router-dom";

const Login = (props) => {
  const [loggingIn, setLoggingIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errorData, setErrorData] = useState({'username': 0,'password': 0});
  const navigate = useNavigate();

  if (props.loggedIn) {
    navigate('/');
  }
  
  const login = async () => {
    setLoggingIn(true);
    try {
      const response = await fetch(
        apiHost+'/api/vinylVault/login.php', {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({'username': username, 'password': password, 'remember-me': rememberMe}),
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (!response.ok) {
        throw new Error('Oops! Looks like we may have messed up, try again later!')
      }
      const result = await response.json();
      if (result['data']['errors']) {
        setErrorData(result['data']['data']);
        console.log(result['data']['data']);
      } else if (result['data']['loggedIn']) {
        navigate("/");
      }
    } catch (error) {
    }
    setLoggingIn(false);
  }
  const attemptLogin = (event) => {
    event.preventDefault();
    login();
  }
  return (
    <section className={(props.className || "") + (loggingIn ? " pointer-events-none cursor-progress brightness-50" : "")}>
      <h1 className="hidden">Login</h1>
      <form className="flex flex-col gap-2 sm:p-6 p-2 sm:mt-12 max-w-sm mx-auto sm:bg-gray-900 sm:rounded-lg" onSubmit={attemptLogin}>
        <h2 className="text-white text-4xl mb-2">Login</h2>
        <Input type="text" name="username" id="username" label="Username" placeholder="Username" labelWidth="w-24" value={username} setValue={setUsername} error={errorData['username']} />
        <Input type="password" name="password" id="password" label="Password" placeholder="Password" labelWidth="w-24" value={password} setValue={setPassword} error={errorData['password']} />
        <div>
          <Checkbox id="remember-me" name="remember-me" label="Keep me logged in" value={rememberMe} setValue={setRememberMe} />
        </div>
        <Button type="submit" text="Login" className="mt-2" />
      </form>
    </section>
  );
};

export default Login;