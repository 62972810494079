import LoadingIcon from "./Components/LoadingIcon";
import { apiHost } from "../Functionality/SharedVars";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const getAlbums = async () => {
      try {
        const response = await fetch(
          apiHost+'/api/vinylVault/logout.php', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({a: 'egg'})
          }
        );
        if (!response.ok) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const result = await response.json();
        if (result) {}
      } catch (error) {} finally {
        navigate('/');
      }
    }

    getAlbums();
  }, [navigate]);

  return (
    <section className="flex pt-16 px-8 items-center flex-col text-white text-center text-2xl gap-4 max-w-screen-md mx-auto">
      <h1 className="hidden">Locations</h1>
      <p>Logging you out</p>
      <LoadingIcon className=""/>
    </section>
  );
}

export default Logout;