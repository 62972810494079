import { Link } from 'react-router-dom';
import { apiHost } from '../../Functionality/SharedVars';
import { useState, useContext } from 'react';
import { SearchContext } from "../../Functionality/SearchContext";

const MixedList = ({dataArray, singleCol}) => {
  let children = [];
  for (let i = 0; i < dataArray.length; i++) {
    let artLocation = apiHost+"/api/vinylVault/albumArt/" + dataArray[i].art;
    let href = "/" + dataArray[i].type + "/" + dataArray[i].id;
    children.push(
      <ListItem key={i} secId={dataArray[i].secId} href={href} artLocation={artLocation} name={dataArray[i].name} artist={dataArray[i].artist ? dataArray[i].artist : false} count={dataArray[i].count ? (dataArray[i].count + " Albums") : false} singleCol={singleCol} />
    );
  }
  return (
    <div className={"max-w-screen-2xl mx-auto grid gap-2 p-2 " + (singleCol ? "grid-cols-1" : "grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 xl:grid-cols-6")}>
      {children}
    </div>
  );
}

const ListItem = ({href, artLocation, name, artist, count, singleCol, secId}) => {
  const {setSearchValue} = useContext(SearchContext);
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Link to={href + ((!(secId === null) && !(secId === undefined)) ? "/"+secId : "")} className={"flex group flex-col rounded-lg overflow-hidden group hover:scale-[1.01] " + (singleCol ? "h-40" : "aspect-video")} onClick={e => setSearchValue("")}>
      <div className='h-0 sm:brightness-75 group-hover:filter-none'>
        <img src={artLocation} alt='' className={"w-full aspect-video object-cover blur " + (isLoaded ? "" : " hidden")} onLoad={(e) => {setIsLoaded(true);}} />
        <span className={isLoaded ? "hidden" : "w-full aspect-video bg-gray-800 block animate-pulse"}></span>
      </div>
      <div className='grow justify-end flex-col flex'>
        <div className='p-1 bg-gray-900/50 text-white backdrop-blur-sm'>
          {artist ? <p className='truncate text-xs leading-snug font-light'>{artist}</p> : null}
          <p className='truncate leading-snug'>{name}</p>
          {count ? <p className='truncate text-xs leading-snug font-light'>{count}</p> : null}
        </div>
      </div>
    </Link>
  )
}

export default MixedList;