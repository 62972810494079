import { Link } from "react-router-dom";

const Tab = ({Icon, name, location, className, menuOpenData}) => {
  return (
    <Link onClick={(e) => {menuOpenData.setMenuOpen(!menuOpenData.menuOpen)}} to={location} className={"rounded-lg flex bg-gray-800 p-4 items-center gap-1 text-xl hover:bg-gray-900 active:bg-gray-950 h-16 transition-all " + className}>
      <Icon className='text-3xl'/>
      <p>{name}</p>
    </Link>
  )
}

export default Tab;