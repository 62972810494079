import { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { SearchContext } from "../Functionality/SearchContext";

const Tab = ({Icon, name, location, altLocation = ""}) => {
  const {setSearchValue} = useContext(SearchContext);
  const path = useLocation();
  const [classes, setClasses] = useState("");
  const baseClasses = "active:bg-gray-950 md:h-auto md:w-full md:rounded-lg flex flex-col justify-center items-center h-full text-white text-sm aspect-square hover:bg-gray-900 transition-colors duration-150";

  altLocation = altLocation.toLowerCase().split('/')[1];
  useEffect(() => {
    switch (path.pathname.toLowerCase().split('/')[1]) {
      case location.toLowerCase().split('/')[1]:
        setClasses("bg-gray-700 " + baseClasses);
        break;

      case altLocation:
        setClasses("bg-gray-700 " + baseClasses);
        break;
    
      default:
        setClasses("" + baseClasses);
        break;
    }
  }, [path.pathname, location, altLocation]);

  return (
    <Link to={location} className={classes} onClick={e => setSearchValue("")}>
      <Icon className="text-4xl"/>
      <p>{name}</p>
    </Link>
  );
};

export default Tab;