import Nav from './Nav/Nav';
import Header from './Header/Header';
import Main from './Pages/Main';
import SearchProvider from './Functionality/SearchContext';
import { apiHost } from './Functionality/SharedVars';
import { useLocation, useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';

function App() {
  const [userId, setUserId] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const [checkLogin, setcheckLogin] = useState(false);
  const path = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const getAlbums = async () => {
      try {
        const response = await fetch(
          apiHost+'/api/vinylVault/checkLogin.php', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({a: 'egg'})
          }
        );
        if (!response.ok) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const result = await response.json();
        if (result['data']['loggedIn']) {
          setLoggedIn(true);
          setUserId(result['data']['id']);
          setUsername(result['data']['username']);
        } else {
          setLoggedIn(false);
          setUserId(null);
          setUsername(null);
        }
      } catch (error) {}
      setcheckLogin(true);
    }

    getAlbums();
  }, [path.pathname, checkLogin, navigate]);

  return (
    <SearchProvider>
      <Header userId={userId} loggedIn={loggedIn} username={username} />
      <main className='md:pl-20 md:pb-0 min-h-screen bg-gray-950 py-20'>
        <Main loggedIn={loggedIn} userId={userId} />
      </main>
      <Nav />
    </SearchProvider>
  );
}

export default App;
