import { useEffect, useState } from "react";
import AlbumCards from "./Components/AlbumCards";
import { apiHost } from "../Functionality/SharedVars";
import LoadingIcon from "./Components/LoadingIcon";

const Home = (props) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [responseCode, setResponseCode] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getAlbums = async () => {
      try {
        const response = await fetch(
          apiHost+'/api/vinylVault/getAlbums.php', {
            method: 'POST',
            body: JSON.stringify({a: 'egg'})
          }
        );
        setResponseCode(response.status);
        if (!response.ok) {
          throw new Error('Oops! Looks like we may have messed up, try again later!')
        }
        const result = await response.json();
        setData(result.data);
        setError(null);
      } catch (error) {
        setError(error);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    getAlbums();
  }, [loading]);

  if (error === null && data === null) {
    return (
      <section className={(props.className || "") + " flex p-20 items-center flex-col"}>
        <h1 className="hidden">Albums</h1>
        <LoadingIcon className=""/>
      </section>
    );
  } else if (data === null && error != null) {
    return (
      <section className={(props.className || "") + " text-white flex flex-col align-center text-center p-8 text-2xl gap-4 max-w-screen-md mx-auto"}>
        <h1 className="hidden">Albums</h1>
        <p>Oops! Looks like we may have messed up, try again later!</p>
        <p className="text-xl text-gray-500">Response code: {responseCode ? responseCode : "503"}</p>
      </section>
    );
  } else {
    return (
      <section className={(props.className || "")}>
        <h1 className="hidden">Albums</h1>
        <AlbumCards albums={data}/>
      </section>
    );
  }
};

export default Home;